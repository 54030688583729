import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { Typography, Box, Paper, Button, TextField, Stack, Divider } from '@mui/material'
import { WaveSurfer, WaveForm, Region } from "wavesurfer-react";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export default function Wave({
  audioUrl,
  regionStart,
  setRegionStart,
  regionEnd,
  setRegionEnd,
  onSubmit
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [progressPercent, setProgressPercent] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    if (wavesurferRef.current) {
      // Обновляем текущее время и процент прогресса при воспроизведении
      wavesurferRef.current.on('audioprocess', () => {
        const currentTime = wavesurferRef.current.getCurrentTime();
        const duration = wavesurferRef.current.getDuration();
        const progress = (currentTime / duration) * 100;

        setCurrentTime(currentTime);
        setProgressPercent(progress);
      });

      // Обновляем текущее время и прогресс при клике по дорожке
      wavesurferRef.current.on('seek', (progress) => {
        const duration = wavesurferRef.current.getDuration();
        const newTime = progress * duration;
        const progressPercent = progress * 100;

        setCurrentTime(newTime);
        setProgressPercent(progressPercent);
      });
    }

    // Удаляем обработчики при размонтировании
    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.un('audioprocess');
        wavesurferRef.current.un('seek');
      }
    };
  }, []);

  const plugins = useMemo(() => {
    return [
      {
        key: "regions",
        plugin: RegionsPlugin,
        options: {
          dragSelection: true
        },
      }
    ].filter(Boolean);
  }, []);

  const regions = {
      id: "region-2",
      start: regionStart,
      end: regionEnd,
      color: "rgba(225, 195, 100, .5)",
      data: {
        systemRegionId: 32,
      }
  }

  const regionsRef = useRef(regions);

  useEffect(() => {
    regionsRef.current = regions;
  }, [regions]);

  const wavesurferRef = useRef();

  const handleWSMount = useCallback((waveSurfer) => {
    wavesurferRef.current = waveSurfer;

    wavesurferRef.current.on("ready", () => {
      console.log("WaveSurfer is ready");
      setIsLoaded(true);
      setLoadingProgress(100)
    });

    wavesurferRef.current.on("loading", (data) => {
      console.log("loading --> ", data);
      setLoadingProgress(data);
    });

    if (window) {
      window.surferidze = wavesurferRef.current;
    }
  }, []);

  useEffect(() => {
    if (wavesurferRef.current && audioUrl) {
      wavesurferRef.current.load(audioUrl);
    }
  }, [audioUrl]);

  const play = useCallback(() => {
    wavesurferRef.current.playPause();
  }, []);

  const handleRegionUpdate = useCallback((region) => {
    setRegionStart(region.start.toFixed(2));  // Обновляем стартовое значение
    setRegionEnd(region.end.toFixed(2));      // Обновляем конечное значение
  }, []);


  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ position: 'relative', mt: 6 }}>
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            top: '-30px', // Над дорожкой
            left: `${progressPercent}%`, // Позиция текущего времени по прогрессу
            transform: 'translateX(-50%)', // Центрирование по оси X
            pointerEvents: 'none', // Отключаем события мыши
            background: 'rgba(0,0,0,0.6)',
            padding: '1px 4px',
            borderRadius: '4px',
            color: '#fff',
          }}
        >
          {currentTime.toFixed(2)}
        </Typography>

        <WaveSurfer
          plugins={plugins}
          onMount={handleWSMount}
          cursorColor="transparent"
          container="#waveform"
          waveColor="#00aaff" // Голубой цвет
          progressColor="#1565C0" // Прогресс воспроизведения
        >
          <WaveForm>
          {isLoaded &&
              <Region
                onUpdate={handleRegionUpdate}
                {...regions}
              />
          }
          </WaveForm>
        </WaveSurfer>
      </Box>

      <Box sx={{ mt: 2, textAlign:'center' }}>
        <Button onClick={play} sx={{
          border: '1px solid #1976D2',
          borderRadius: 9999,
          padding: '8px 16px',
          justifyContent: 'center'
        }}>
          <PlayArrowIcon color="primary" fontSize="large" />
          <Divider />
          <PauseIcon color="primary" fontSize="large" />
        </Button>
        {/* <Button >Воспроизведение / Пауза</Button> */}
      </Box>

      <Stack direction='row' sx={{ mt: 4 }}>
        <TextField
          label="Start"
          value={regionStart}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          onChange={(e) => setRegionStart(e.target.value)}
        />
        <TextField
          label="End"
          value={regionEnd}
          variant="outlined"
          fullWidth
          sx={{ ml: 2 }}
          onChange={(e) => setRegionEnd(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ height: '56px', ml: 2 }}
          onClick={onSubmit}
        > Обрезать песню
        </Button>
      </Stack>

    </Paper>
  )
}
