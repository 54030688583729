import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { fetchInProgressSongs, fetchSongsByUserId, fetchSongById } from '../../redux/slices/songs'
import { fetchAuthMe } from '../../redux/slices/auth'
import { Navigate, useNavigate, useParams  } from 'react-router-dom'
import { Typography, Box, Grid, Paper } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import UserInfo from '../../components/UserInfo'
import Cookies from 'js-cookie';
import localAxios from '../../axios'
import axios from 'axios'
import { BASE_URL, SUNO_API_URL, GENERAL, LIMIT_10 } from '../../config'
import Wave from "./Wave.js";
import Backdrop from '../../components/Backdrop'

export default function Crop() {
  useEffect(() => {
    const value = Cookies.get('isRegister')
    if (!value) { Cookies.set('isRegister', true, { expires: 3650 }) }
  }, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { itemId } = useParams();

  const [backdropVisible, setBackdropVisible] = useState(false)
  const [flag, setFlag] = useState(true)
  const [regionStart, setRegionStart] = useState(5);
  const [regionEnd, setRegionEnd] = useState(15);
  const [audioUrl, setAudioUrl] = useState()

  const user = useSelector(state => state.auth.data)
  const isAuth = useSelector(selectIsAuth)
  const userId = user?._id

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' />
  }

  useEffect(() => {
    if (itemId) {
      dispatch(fetchSongById(itemId));
    }
  }, [dispatch, itemId]);
  const { song } = useSelector(state => state.songs)

  useEffect(() => {
    if (song && song.status === 'loaded' && song.items) {
      const songOwnerId = song.items.user._id; // Используй индексацию для доступа к первому элементу

      if (songOwnerId !== userId || !songOwnerId) {
        navigate('/cabinet/studio');
        return;
      }

      setAudioUrl(song.items.song.audio_url);
    }
  }, [song, userId, navigate]);

  async function onSubmit (e) {
    e.preventDefault()
    setBackdropVisible(true)

    try {
        let requestData = {
            "crop_start_s": parseFloat(regionStart),
            "crop_end_s": parseFloat(regionEnd),
            "audio_id": song.items.song.id
        }

        await axios.post(
            `${SUNO_API_URL}/api/crop_audio`,
            requestData,
            {
                headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
                }
            }
        ).then(response => {
            console.log('AUDIO SENT TO SUNO', response)
            saveSongs(response.data.action_clip_id)
        })
    } catch(e) {
        console.warn(e)
    }
  }

  async function saveSongs (songId) {
    const songValues = {
        user: userId,
        status: GENERAL,
        price: 0,
        songs: [{
          id: songId,
          title: song.items.song.title,
          audio_url: '',
          status: 'processing'
        }]
    }

    try {
        await localAxios.post(`${BASE_URL}/song`, songValues).then(()=> {
            dispatch(fetchAuthMe())
            dispatch(fetchSongsByUserId({ userId, status: GENERAL, page: 1, limit: LIMIT_10 }))
            setBackdropVisible(false)
            setFlag(!flag)
            dispatch(fetchInProgressSongs(userId))
            navigate('/cabinet/studio')
        })
    } catch (e) {
        console.log('e', e)
    }
  }

  if (!userId || !audioUrl) {
    return (<LoadingButton />);
  }

  return (
    <Box>
        <Backdrop open={backdropVisible} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
              <Paper sx={{ p: 2 }}>
                  <UserInfo user={user} />
              </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
              <Grid>
                <Paper sx={{ p: 2 }}>
                  <Typography component="h1" variant="h4" sx={{ textAlign: 'center' }}>
                    Обрезка аудио
                  </Typography>
                  <Typography component="body2" variant="body2" sx={{ mt: 2 }}>
                    Выбранный эпизод будет сохранен как новая песня. Все, что останется за пределами выбранной
                    части, будет удалено. После создания нового аудиофайла вы всегда сможете вырезать из него
                    еще что-нибудь.
                  </Typography>

                  <Wave
                    audioUrl={audioUrl}
                    regionStart={regionStart}
                    setRegionStart={setRegionStart}
                    regionEnd={regionEnd}
                    setRegionEnd={setRegionEnd}
                    onSubmit={onSubmit}
                  />
                </Paper>
              </Grid>
          </Grid>
        </Grid>
      </Box>
  )
}
