import React, { useState, useEffect } from 'react'
import { Stack, Typography, Box, Link } from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { fetchRemoveSong } from '../../redux/slices/songs'
import { setSelectedSong } from '../../redux/slices/general';
import { WEBSOCKET_BASE_URL, BASE_URL } from '../../config';
import { formatSeconds } from '../../clientHelpers/formatSeconds';
import CardMenu from './CardMenu';
import Modal from '../Modal'
import PlayButton from '../PlayButton';
import s from './index.module.css'
export default function SongCard({ item: initialItem, filter }) {
  const dispatch = useDispatch()
  const [item, setItem] = useState(initialItem)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  function onRemoveHandler (itemId) {
    dispatch(setSelectedSong({}))
    dispatch(fetchRemoveSong(itemId))
    setShowRemoveModal(false)
  }
  return (
    <>
      <Modal
        open={showRemoveModal}
        title='Вы уверены, что хотите удалить песню?'
        onClose={()=> setShowRemoveModal(false)}
        onCancel={()=> setShowRemoveModal(false)}
        onSuccess={()=> onRemoveHandler(item._id)}
      />
      <Stack className={s.card}>
          <Link
            component='button'
            onClick={()=> dispatch(setSelectedSong(item.song)) }
          >
            <img className={s.image} src={`${BASE_URL}/uploads/oktava.png`} />
          </Link>
          <Stack sx={{ padding: '8px' }} direction='row' justifyContent='space-between' >
            <Stack direction={'column'}>
            {/* {
                item.song.type === 'edit_crop' && (
                  <Box sx={{ display: 'inline-flex' }}>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        height: '14px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        borderRadius: '4px',
                        background: '#bf00a5',
                        color: '#fff',
                        mb: 0.5
                      }}>Эссенция песни</Typography>
                    </Box>
                )
              } */}
              {
                item.song.metadata?.type === 'concat' && (
                  <Box sx={{ display: 'inline-flex' }}>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        height: '14px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        borderRadius: '4px',
                        background: '#218e00',
                        color: '#fff',
                        mb: 0.5
                      }}>Финальная версия</Typography>
                    </Box>
                )
              }
              {
                item.song.metadata?.history && (
                  <Box sx={{ display: 'inline-flex' }}>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        height: '14px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        borderRadius: '4px',
                        background: 'orange',
                        mb: 0.5
                      }}>Часть { item.song.metadata?.history.length + 1}</Typography>
                    </Box>
                )
              }
              <Link
                sx={{ textAlign: 'left', fontWeight: 600 }}
                href={'/song/' + item._id}
                target='_blank'
              >
                { item.song.title }
              </Link>
              <Box sx={{ display: 'inline-flex', mt: 1 }}>
                <Typography variant='caption' color='gray'>{item.song.tags}</Typography>
              </Box>
            </Stack>
            <PlayButton song={item.song} />
          </Stack>
          <Stack sx={{ padding: '8px', paddingTop: 0, paddingRight: '16px' }} direction='row' justifyContent='space-between' >
              <Typography variant='caption'>{ moment(item.song.created_at).format('DD.MM.YYYY HH:mm') }</Typography>
              <Typography variant='caption'>{ item.song.duration ? formatSeconds(item.song?.duration) : '-- : --' }</Typography>
          </Stack>
          {
            !item.song.duration && (
              <Box sx={{
                background: 'orange',
                padding: '4px'
              }}>
                <Typography sx={{
                  fontSize: '10px',
                  lineHeight: '12px',
                  textAlign: 'center',
                  color: 'white'
                }}>
                  Мы еще работаем над Вашей песней, но уже сейчас Вы можете начать слушать то, что мы успели сделать для Вас
                </Typography>
              </Box>
            )
          }
          <CardMenu
            item={item}
            setShowRemoveModal={setShowRemoveModal}
          />
      </Stack>
    </>
  )
}
