import React from 'react'
import { Stack, Typography, Box, Tooltip, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setExtendSong, setForm, setSelectedSong } from '../../redux/slices/general';
import moment from 'moment';
import s from './index.module.css'
import PlayButton from '../PlayButton';
import { formatSeconds } from '../../clientHelpers/formatSeconds';
import { BASE_URL } from '../../config'
export default function ExtendSongCard({ song, isExtendFrom }) {
  const dispatch = useDispatch()
  return (
    <>
      <Stack className={s.card}>
          { !isExtendFrom && (
            <Link
              component='button'
              onClick={()=> {
                      dispatch(setForm({
                          isCustomMode: true,
                          isInstrumental: false,
                          isExtend: false,
                          prompt: '',
                          tags: '',
                          title: '',
                          audio_id: '',
                          version: 'v3.5'
                      }))
                      dispatch(setExtendSong({}))
                  }
              }
            >
              <Tooltip title='Отменить продолжение песни' placement='top'>
                <CloseIcon
                  fontSize='small'
                  sx={{
                    position: 'absolute',
                    right: '8px',
                    top: '8px',
                    borderRadius: '50%',
                    background: '#fff',
                    padding: '2px'
                  }}
                />
              </Tooltip>
            </Link>
            )
          }
          <img className={s.image} src={`${BASE_URL}/uploads/oktava.png`} />

          <Stack sx={{ padding: '8px' }} direction='row' justifyContent='space-between' >
            <Stack direction={'column'}>
              <Typography variant='body1' sx={{ fontWeight: 600 }}>Продолжение песни</Typography>
              <Link
                sx={{ textAlign: 'left', fontWeight: 600 }}
                component='button'
                onClick={()=> dispatch(setSelectedSong(song)) }
              >
                { song.title }
              </Link>
              <Box sx={{ display: 'inline-flex', mt: 1 }}>
                <Typography variant='caption' color='gray'>{song.tags || ''}</Typography>
              </Box>
            </Stack>
            <PlayButton song={song} />
          </Stack>
          <Stack sx={{ padding: '8px', paddingTop: 0, paddingRight: '16px' }} direction='row' justifyContent='space-between' >
              <Typography variant='caption'>{ moment(song.created_at).format('DD.MM.YYYY HH:mm') }</Typography>
              <Typography variant='caption'>{ song.duration ? formatSeconds(song?.duration) : '-- : --' }</Typography>
          </Stack>
      </Stack>
    </>
  )
}
