import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography, Box, Link, Avatar } from '@mui/material';
import moment from 'moment';
import { BASE_URL } from '../../config'
import localAxios from '../../axios'
import { stringToColor } from '../../clientHelpers/stringToColor'
import { formatSeconds } from '../../clientHelpers/formatSeconds'
import { selectIsAuth } from '../../redux/slices/auth'
import PlayButton from '../PlayButton';
import s from './index.module.css'
import { updateLikeCount  } from '../../redux/slices/songs'
import SongStats from '../SongStats'

export default function SongCardPublic({ item, likesArray, initialSongList }) {
  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsAuth)
  const user = useSelector(state => state.auth.data)

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        position: 'absolute',
        left: '12%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        width: 60,
        height: 60,
        border: '4px solid #fff'
      },
      children: `${name[0]}`,
    };
  }

  async function addLike (e, item) {
    e.preventDefault()
    try {
      const data = await localAxios.post(`${BASE_URL}/song/like`, {
        itemId: item._id,
        sender: user._id,
        receiver: item.user._id
      })
      dispatch(updateLikeCount({  itemId: item._id, likeCount: data.data.likeCount, initialSongList }));
    } catch(e) {
        console.warn(e)
    }
  }

  const likesIds = likesArray.items.map(item => item.item)
  const likeColor = likesIds.includes(item._id) ? '#1976d2' : '#696969'

  return (
    <Stack className={s.card}>
        <Avatar
          {...stringAvatar(`${item.user.name}`)}
          src={`${BASE_URL}${item.user.avatar}`}
        />
        <img className={s.image} src={`${BASE_URL}/uploads/oktava.png`} />

        <Stack sx={{ padding: '8px' }} direction='row' justifyContent='space-between' >
          <Stack direction={'column'}>
            <Link
              href={`/song/${item._id}`}
              sx={{ textAlign: 'left', fontWeight: 600 }}
            >
              { item.song.title }
            </Link>
            <Box sx={{ display: 'inline-flex', mt: 1 }}>
              <Typography variant='caption' color='gray'>{item.song.tags}</Typography>
            </Box>
          </Stack>
          <PlayButton song={item.song} />
        </Stack>

        <Stack sx={{ padding: '8px', paddingTop: 0, paddingRight: '16px' }} direction='row' justifyContent='space-between' >
            <Typography variant='caption'>{ moment(item.song.created_at).format('DD.MM.YYYY HH:mm') }</Typography>
            <Typography variant='caption'>{ item.song.duration ? formatSeconds(item.song?.duration) : '-- : --' }</Typography>
        </Stack>

        <Stack
          sx={{ background: '#eee', padding: 0.5, pl: 1, pr: 2 }}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
            <Typography component='span' variant='body2'>
              <Link href={`/user/${item.user.login}`} underline="none">{ item.user.name }</Link>
            </Typography>

            <SongStats
              item={item}
              likesArray={likesArray}
              initialSongList={initialSongList}
            />
        </Stack>
    </Stack>
  )
}
