import React, { useState } from 'react'
import { Stack, Box, IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DeleteIcon from '@mui/icons-material/Delete';
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ReUsePromptIcon from '@mui/icons-material/AutoAwesomeMotion';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import FlagIcon from '@mui/icons-material/Flag';
import { ARCHIVE, FAVORITE, GENERAL, SUNO_API_URL, BASE_URL, LIMIT_10 } from '../../../config';
import { setForm, setExtendSong, setSelectedSong } from '../../../redux/slices/general';
import { fetchSongsByUserId, fetchInProgressSongs } from '../../../redux/slices/songs'
import Modal from '../../Modal';
import localAxios from '../../../axios'
import DownloadLink from '../../DownloadLink'

export default function CardMenu({
    item,
    setIsPublished,
    setShowRemoveModal,
    setShowPublishedModal,
    onChangeStatusHandler
}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const me = useSelector(state => state.auth.data)
    const userId = me?._id

    const [showCreateFullSongModal, setShowCreateFullSongModal] = useState(false)

    function reuseSettings (item) {
        const requestData = {
            "prompt": item.prompt,
            "tags": item.tags || '',
            "negative_tags": item.negative_tags || '',
            "title": item.title,
            "isCustomMode": true,
            "isInstrumental": false,
            "version": 'v3.5'
        }
        dispatch(setForm(requestData))
    }

    function extendSettings (item) {
        const requestData = {
            "prompt": item.prompt,
            "tags": item.tags || '',
            "negative_tags": item.negative_tags || '',
            "title": item.title,
            "audio_id": item.id,
            "isCustomMode": true,
            "isExtend": true,
            "isInstrumental": false,
            "version": 'v3.5'
        }
        dispatch(setForm(requestData))
        dispatch(setExtendSong(item))
    }

    function createFullSongHandler (song) {
        fullSettings(song)
        dispatch(setSelectedSong(song))
    }

    async function fullSettings (item) {
        const requestData = {
            "clip_id": item.id
        }

        await axios.post(
            `${SUNO_API_URL}/api/concat`,
            requestData,
            {
                headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
                }
            }
        ).then(response => {
            saveFullSongs(response.data)
        })
    }

    async function saveFullSongs (songs) {
        console.log('SAVE FULL SONG START', songs)

        const songValues = {
            user: userId,
            status: GENERAL,
            songs: [
                songs
            ]
        }

        try {
            await localAxios.post(`${BASE_URL}/song`, songValues).then(()=> {
                dispatch(fetchSongsByUserId({ userId, status: GENERAL, page: 1, limit: LIMIT_10 }))
                dispatch(fetchInProgressSongs(userId))
            })
        } catch (e) {
            console.log('e', e)
        }
    }

    return (
        <>
            <Modal
                open={showCreateFullSongModal}
                title='Создать полную версию песни?'
                onClose={()=> setShowCreateFullSongModal(false)}
                onCancel={()=> setShowCreateFullSongModal(false)}
                onSuccess={()=> createFullSongHandler(item.song)}
            />
            <Stack direction='row' sx={{
                justifyContent: 'space-between',
                background: '#eee'
            }}>

                <Box>
                    {
                        ((!!me?.voiceId && me.voiceId !==  item.song.id) || (!me?.voiceid)) && (
                            <Tooltip title='Переиспользовать настройки этой песни' placement='top'>
                                <IconButton onClick={()=> {
                                    reuseSettings(item.song)
                                    dispatch(setSelectedSong(item.song))
                                }}>
                                <ReUsePromptIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        )
                    }


                    {
                        item.song?.duration && (
                            <Tooltip title='Создать продолжение песни' placement='top'>
                                <IconButton onClick={()=> {
                                    extendSettings(item.song)
                                    dispatch(setSelectedSong(item.song))
                                }}>
                                <WifiProtectedSetupIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        )
                    }

                    {
                        item.song.metadata?.history?.length && (
                            <Tooltip title='Соединить все части песни' placement='top'>
                                <IconButton onClick={()=> {
                                    setShowCreateFullSongModal(true)
                                }}>
                                <FlagIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        )
                    }

                    {/* {
                        item.song?.duration && (
                            <Tooltip title='Обрезать песню' placement='top'>
                                <IconButton onClick={()=> {
                                    navigate('/cabinet/crop/' + item._id)
                                }}>
                                <ContentCutIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        )
                    } */}
                </Box>

                <Box>
                    <Tooltip title='Понравился этот вариант песни' placement='top'>
                    <IconButton onClick={()=> {
                        const status = item.status !== FAVORITE ? FAVORITE : GENERAL
                        onChangeStatusHandler(item._id, status)
                    }}>
                        <ThumbUpIcon fontSize='small' color={ item.status === FAVORITE ? 'primary' : undefined } />
                    </IconButton>
                    </Tooltip>

                    <Tooltip title='Не понравился этот вариант песни' placement='top'>
                    <IconButton onClick={()=> {
                        const status = item.status !== ARCHIVE ? ARCHIVE : GENERAL
                        onChangeStatusHandler(item._id, status)
                    }}>
                        <ThumbDownIcon sx={{ width: 18, height: 18 }} color={ item.status === ARCHIVE ? 'primary' : undefined } />
                    </IconButton>
                    </Tooltip>

                    {
                           ((!!me?.voiceId && me.voiceId !==  item.song.id) || (!me?.voiceid)) && (
                            <Tooltip title='Опубликовать на сайте' placement='top'>
                            <IconButton onClick={()=> {
                                setIsPublished(!item.published)
                                setShowPublishedModal(true)
                            }}>
                                <RadioButtonCheckedIcon fontSize='small' color={ item.published ? 'primary' : undefined } />
                            </IconButton>
                            </Tooltip>
                            )
                    }

                </Box>

                <Box>
                    {
                        item.song?.duration && (
                            <DownloadLink
                                url={item.song.audio_url}
                                filename={item.song.title + '.mp3'}
                            >
                                <DownloadIcon fontSize='small' sx={{ mr: 0.5, mt: 0.5 }} />
                            </DownloadLink>
                        )
                    }

                    {
                       ((!!me?.voiceId && me.voiceId !==  item.song.id) || (!me?.voiceid)) && (
                            <Tooltip title='Удалить полностью' placement='top'>
                                <IconButton onClick={()=> setShowRemoveModal(true)}>
                                    <DeleteIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        )
                    }

                </Box>

            </Stack>
        </>
    )
}
