import React, { useEffect, useState, useRef  } from 'react'
import { Typography, TextField, Button,  Box, Stack, Alert, Link, Select, MenuItem } from '@mui/material'
import axios from 'axios'
import localAxios from '../../../axios'
import { BASE_URL, SUNO_API_URL, GENERAL, GET_AUDIO_INTERVAL, LIMIT_10 } from '../../../config'
import { fetchAuthMe } from '../../../redux/slices/auth'
import { fetchSongsByUserId, fetchInProgressSongs } from '../../../redux/slices/songs'
import { setExtendSong, setForm } from '../../../redux/slices/general'
import { useDispatch, useSelector } from 'react-redux'
import Backdrop from '../../../components/Backdrop'
import ExtendSongCard from '../../../components/ExtendSongCard'
import { getFirstThreeWords } from '../../../clientHelpers/getFirstThreeWords'
import Modal from '../../../components/Modal'

const TAGS = ['pop', 'rock', 'folk', 'dance', 'jazz', 'chanson']

export default function Settings({ me, inProgressSongs, flag, setFlag, updateSongsInfo }) {
    const dispatch = useDispatch()

    const userId = me?._id
    const isNotEnoughCredits = me?.credits < 10
    const isInProgressSongs = inProgressSongs.length

    const inProgressSongsRef = useRef(inProgressSongs)
    const intervalRef = useRef(null)

    const [backdropVisible, setBackdropVisible] = useState(false)
    const [errorTime, setErrorTime] = useState()

    const [showOwnVoiceModal, setShowOwnVoiceModal] = useState(false)

    const { form, extendSong } = useSelector(state => state.general)

    useEffect(() => {
        if (Object.keys(extendSong).length) {
            dispatch(setForm({...form, continueAt: extendSong.duration.toFixed(0)}))
            const minutes = Math.floor(extendSong.duration.toFixed(0) / 60)
            const seconds = extendSong.duration.toFixed(0) % 60
            setNewMin(minutes);
            setNewSec(seconds);
        }
    }, [JSON.stringify(extendSong), dispatch])

    useEffect(() => {
        inProgressSongsRef.current = inProgressSongs;

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (inProgressSongs.length > 0) {
            intervalRef.current = setInterval(() => {
                const currentInProgressSongs = inProgressSongsRef.current;
                if (currentInProgressSongs.length > 0) {
                    console.log('ENTER');
                    updateSongsInfo();
                } else {
                    console.log('EXIT');
                    clearInterval(intervalRef.current);
                    dispatch(fetchSongsByUserId({ userId, status: GENERAL, page: 1, limit: LIMIT_10 }));
                }
            }, GET_AUDIO_INTERVAL);
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [inProgressSongs, dispatch])

    async function onSubmit (e) {
        e.preventDefault()

        const continueAt = newMin * 60 + newSec

        if (form.isExtend) {
            if(continueAt > extendSong.duration.toFixed(0)) {
                setErrorTime('Неправильно указано время для продолжения песни. Продолжительность песни меньше, чем указанные вами значения.')
                return null
            }
        }

        setBackdropVisible(true)

        try {
            if (form.isCustomMode && !form.isExtend) {
                let requestData = {
                    "prompt": form.prompt,
                    "tags": form.tags?.length ? form.tags : 'pop',
                    "negative_tags": form.negative_tags?.length ? form.negative_tags : '',
                    "title": form.title.length ? form.title : getFirstThreeWords(form.prompt),
                    "make_instrumental": form.isInstrumental,
                    "wait_audio": false
                }

                await axios.post(
                    `${SUNO_API_URL}/api/custom_generate`,
                    requestData,
                    {
                        headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                        }
                    }
                ).then(response => {
                    saveSongs(response.data)
                })
            }

            if (form.isCustomMode && form.isExtend) {
                let requestData = {
                    "prompt": form.prompt,
                    "tags": form.tags?.length ? form.tags : 'pop',
                    "negative_tags": form.negative_tags?.length ? form.negative_tags : '',
                    "title": form.title.length ? form.title : getFirstThreeWords(form.prompt),
                    "audio_id": form.audio_id,
                    "continue_at": continueAt + '.00'
                }

                await axios.post(
                    `${SUNO_API_URL}/api/extend_audio`,
                    requestData,
                    {
                        headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                        }
                    }
                ).then(response => {
                    saveSongs(response.data.clips)
                })
            }

        } catch(e) {
            console.warn(e)
        }
    }

    async function saveSongs (songs) {
        if (Object.keys(extendSong).length) {
            songs[0].title = extendSong.title
            songs[1].title = extendSong.title
        }

        const songValues = {
            user: userId,
            status: GENERAL,
            songs
        }

        try {
            await localAxios.post(`${BASE_URL}/song`, songValues).then(()=> {
                dispatch(fetchAuthMe())
                dispatch(fetchSongsByUserId({ userId, status: GENERAL, page: 1, limit: LIMIT_10 }))
                setBackdropVisible(false)
                setFlag(!flag)
                dispatch(fetchInProgressSongs(userId))
            })
        } catch (e) {
            console.log('e', e)
        }
    }

    const [newMin, setNewMin] = useState(0);
    const [newSec, setNewSec] = useState(0);

    const handleMinChange = (event) => {
        setErrorTime()
        setNewMin(event.target.value);
    };

    const handleSecChange = (event) => {
        setErrorTime()
        setNewSec(event.target.value);
    };

    function disableBtnErrorText () {
        if (errorTime) {
            return (<Alert severity="warning">{errorTime}</Alert>)
        }
        if (form.prompt && form.prompt.length > 3000) {
            return (<Alert severity="warning">Текст песни не может быть более 3000 символов.</Alert>)
        }
        if (form.tags && form.tags.length > 120) {
            return (<Alert severity="warning">Стили песни не могут быть более 120 символов.</Alert>)
        }
        if (form.negative_tags && form.negative_tags.length > 120) {
            return (<Alert severity="warning">Нежелательные стили не могут быть более 120 символов.</Alert>)
        }
        if (!form.prompt) {
            return (<Alert severity="warning">Заполните поле "Текст песни".</Alert>)
        }
        if (isInProgressSongs) {
            return (<Alert severity="warning">Мы уже создаем для Вас песню. Дождитесь завершения генерации.</Alert>)
        }
        if (isNotEnoughCredits) {
            return (<Alert severity="error">На Вашем счету недостаточно кредитов для создания новой песни</Alert>)
        }
    }

    function dialogContent () {
        return (
            <>
                <Typography variant='body1' sx={{ mb: 2 }}>Всего несколько простых шагов:</Typography>
                <Typography variant='body2' sx={{ mb: 1 }}>
                    1. Запишите свой голос на диктофон или любое другое записывающее устройство.
                    Старайтесь не петь песню, а просто дать пример голоса, чтоб нейросеть не пыталась повторить
                    ваши мотивы
                </Typography>
                <Typography variant='body2' sx={{ mb: 1 }}>
                    2. Отправьте пример вашего голоса к нам на почту hello@oktava.me
                </Typography>
                <Typography variant='body2' sx={{ mb: 1 }}>
                    3. После того, как пример голоса будет добавлен в ваш кабинет, создайте продолжение
                    песни от вашего голоса или нажмите кнопку "Использовать свой голос"
                </Typography>
                <Typography variant='body2'>
                    P.S. В период тестирования сервиса для каждого пользователя мы можем добавить только один вариант голоса.
                </Typography>
            </>
        )
    }

    return (
            <>
                <Modal
                    open={showOwnVoiceModal}
                    title='Как создать песню, используя свой голос?'
                    dialogContent={dialogContent}
                    isCancelButton={false}
                    successButtonText={'Все понятно'}
                    onClose={()=> setShowOwnVoiceModal(false)}
                    onSuccess={()=> setShowOwnVoiceModal(false)}
                />
                <Backdrop open={backdropVisible} />

                <Typography variant="h5" sx={{mb: 2}}>Настройки</Typography>

                {
                    !me?.voiceId && me?.isAdmin && (
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            color="warning"
                            sx={{ mb: 2, fontSize: '12px', textDecoration: 'none' }}
                            onClick={()=> { setShowOwnVoiceModal(true) }
                            }
                        >
                            Хочу использовать свой голос
                        </Button>
                    )
                }

                {
                    form.isExtend && (
                        <Box sx={{ mb: 2 }}>
                            <ExtendSongCard song={extendSong} />

                        </Box>
                    )
                }

                {/* <FormControlLabel
                    sx={{ mb: 1 }}
                    control={
                        <Switch
                            checked={form.isInstrumental}
                            onChange={(e) => dispatch(setForm({...form, isInstrumental: !form.isInstrumental})) }
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Инструментальная музыка"
                /> */}
                {
                    !form.isInstrumental && (
                        <>
                            <Typography
                                variant='body2'
                                sx={{ textAlign: 'right' }}
                            >{ form.prompt ? form.prompt.length : 0 }/3000</Typography>
                            <TextField
                                sx={{ mb: 1 }}
                                margin="none"
                                required
                                fullWidth
                                multiline
                                rows={10}
                                id="prompt"
                                name="prompt"
                                label="Текст песни"
                                value={ form.prompt }
                                onChange={(e) => dispatch(setForm({...form, prompt: e.target.value})) }
                            />
                        </>
                    )
                }
                 <Typography
                    variant='body2'
                    sx={{ textAlign: 'right', mt: 2 }}
                >{ form.tags ? form.tags.length : 0 }/120</Typography>
                <TextField
                    margin="none"
                    sx={{ mb: 1 }}
                    fullWidth
                    id="tags"
                    name="tags"
                    label="Стиль песни (на англ. языке через запятую)"
                    value={ form.tags }
                    onChange={(e) => dispatch(setForm({...form, tags: e.target.value})) }
                />
                <Stack direction='row' sx={{ alignItems: 'center', mb: 1 }}>
                    <Typography sx={{ fontSize: '11px', mr: '4px' }}>Примеры: </Typography>
                    {
                        TAGS.map((item, index) => (
                            <Link
                                sx={{
                                    border: '1px solid #1976D2',
                                    borderRadius: '8px',
                                    paddingLeft: '4px',
                                    paddingRight: '4px',
                                    marginRight: '4px',
                                    fontSize: '11px'
                                }}
                                key={index}
                                component='button'
                                onClick={() => dispatch(setForm({...form, tags: item }))}
                            >
                                {item}
                            </Link>
                        ))
                    }
                </Stack>

                <Typography
                    variant='body2'
                    sx={{ textAlign: 'right', mt: 2 }}
                >{ form.negative_tags ? form.negative_tags.length : 0 }/120</Typography>
                <TextField
                    margin="none"
                    sx={{ mb: 1 }}
                    fullWidth
                    id="negative_tags"
                    name="negative_tags"
                    label="Исключить стили (на англ. языке через запятую)"
                    value={ form.negative_tags }
                    onChange={(e) => dispatch(setForm({...form, negative_tags: e.target.value})) }
                />
                
                <TextField
                    margin="normal"
                    fullWidth
                    id="title"
                    name="title"
                    label="Название композиции"
                    value={ form.title }
                    onChange={(e) => dispatch(setForm({...form, title: e.target.value})) }
                />
                {
                    form.isExtend && extendSong.duration && (
                        <Stack direction='row' sx={{ alignItems: 'center' }}>
                            <Typography>Продолжить с (минуты, секунды)</Typography>
                            <Select
                                sx={{ width: '75px', ml: 1 }}
                                value={newMin}
                                onChange={handleMinChange}
                                inputProps={{ 'aria-label': 'Минуты' }}
                            >
                                {Array.from({ length: Math.floor(extendSong.duration.toFixed(0) / 60) + 1 }, (_, index) => (
                                    <MenuItem key={index} value={index}>
                                        {String(index).padStart(2, '0')}
                                    </MenuItem>
                                ))}
                            </Select>

                            <Select
                                sx={{ width: '75px', ml: 1 }}
                                value={newSec}
                                onChange={handleSecChange}
                                inputProps={{ 'aria-label': 'Секунды' }}
                            >
                                {Array.from({ length: 60 }, (_, index) => (
                                    <MenuItem key={index} value={index}>
                                        {String(index).padStart(2, '0')}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Stack>

                    )
                }

                <Box sx={{ mt: 2}}>
                    {  disableBtnErrorText()  }
                </Box>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 2 }}
                    disabled = {!!errorTime || !!isNotEnoughCredits || !!isInProgressSongs || !form.prompt || (!!form.prompt && form.prompt.length > 3000) }
                    onClick={onSubmit}
                > { form.isExtend ? 'Продолжить песню' : 'Создать песню' }
                </Button>

                {
                    form.isExtend && (
                        <Button
                            type="submit"
                            fullWidth
                            variant="text"
                            onClick={()=> {
                                    dispatch(setForm({
                                        isCustomMode: true,
                                        isInstrumental: false,
                                        isExtend: false,
                                        prompt: '',
                                        tags: '',
                                        negative_tags: '',
                                        title: '',
                                        audio_id: '',
                                        version: 'v3.5'
                                    }))
                                    dispatch(setExtendSong({}))
                                }
                            }
                        > Отменить продолжение песни
                        </Button>
                    )
                }
            </>
    )
}
