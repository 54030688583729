import React from 'react'
import { Container, Grid, Link, Stack, Box } from '@mui/material'

export default function Footer() {

    return (
        <Box sx={{
            background: '#1c1c1c',
            mt: 4,
        }}>
            <Container sx={{
                padding: '50px !important'
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Stack direction='column'>
                            <Link href='/' sx={{ color: '#fff', mb: 1 }}>Главная</Link>
                            <Link href='/signin' sx={{ color: '#fff', mb: 1 }}>Вход</Link>
                            <Link href='/signup' sx={{ color: '#fff', mb: 1 }}>Регистрация</Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack direction='column'>
                            <Link href='/songs' sx={{ color: '#fff', mb: 1 }}>Песни</Link>
                            <Link href='/users' sx={{ color: '#fff', mb: 1 }}>Пользователи</Link>
                            {/* <Link href='/price' sx={{ color: '#fff', mb: 1 }}>Тарифы</Link> */}
                        </Stack>
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                        <Stack direction='column'>
                            <Link href='#' sx={{ color: '#fff', mb: 1 }}>Помощь</Link>
                            <Link href='#' sx={{ color: '#fff', mb: 1 }}>Контакты</Link>
                            <Link href='#' sx={{ color: '#fff', mb: 1 }}>Пользовательское соглашение</Link>
                        </Stack>
                    </Grid> */}
                </Grid>
            </Container>
        </Box>
    )
}
