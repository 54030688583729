import React, { useEffect } from 'react'
import { Typography, Grid, Paper, Box, Stack } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSongById } from '../../redux/slices/songs'
import { fetchUserInfoByLogin } from '../../redux/slices/users'
import { fetchCommentsByItemId } from '../../redux/slices/comments'
import Player from '../../components/Player'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import { addLineBreaks } from '../../clientHelpers/addLineBreaks'
import PlayButton from '../../components/PlayButton'
import UserInfo from '../../components/UserInfo'
import Comment from '../../components/Comment'
import SendComment from '../../components/SendComment'
import { BASE_URL } from '../../config'
import s from './index.module.css'

export default function Song() {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  useEffect(()=> {
      dispatch(fetchSongById(itemId))
  }, [itemId])
  const { song } = useSelector(state => state.songs)
  const aboutSong = song.items.length && song.items[0].song

  useEffect(()=> {
    dispatch(fetchCommentsByItemId(itemId))
  }, [itemId])
  const comments = useSelector(state => state.comments.itemComments)

  const login = song?.items[0]?.user?.login

  useEffect(()=> {
    if (login) {
      dispatch(fetchUserInfoByLogin(login))
    }
  }, [login])
  const user = useSelector(state => state.users.user.items)

  const Item = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

  return (
    <Box sx={{ minHeight: '67vh'}}>
      <Player />
      <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
              <Item>
                  <UserInfo user={user} />
              </Item>
          </Grid>
          <Grid item xs={12} md={8}>
              <Item>
                  <img className={s.image} src={`${BASE_URL}/uploads/oktava.png`} />

                  <Stack direction='row' sx={{
                    justifyContent: 'space-between'
                  }}>
                    <Stack direction={'column'}>
                      <Typography variant='h3' component='h1' sx={{ mt: 2 }}>{ aboutSong.title }</Typography>
                      <Box sx={{ display: 'inline-flex', mt: 1 }}>
                        <Typography variant='caption' color='gray'>{aboutSong.tags}</Typography>
                      </Box>
                    </Stack>
                    <Stack direction='row' sx={{
                      alignItems: 'center',
                      border: '1px solid #1976D2',
                      borderRadius: '32px',
                      padding: '8px',
                      paddingRight: 0,
                      paddingLeft: '24px',
                      height: '60px',
                      marginTop: '24px'
                    }}>
                      <Typography variant='body2'>Слушать песню</Typography>
                      <PlayButton song={aboutSong} style={{ width: '50px', height: '50px'}} />
                    </Stack>
                  </Stack>

                  <Typography variant='body1' sx={{ mt: 2 }}>{ aboutSong.prompt && addLineBreaks(aboutSong.prompt) }</Typography>

                  {
                    comments.items && (
                      <Typography variant='h4' sx={{ mt: 4, textAlign: 'center' }}>Комментарии</Typography>
                    )
                  }
                  {
                    comments.items && comments.items.map((item, index) =>
                      item.level === 1 && (
                        <Comment
                          key={index}
                          item={item}
                        />
                      )
                    )
                  }

                  <Typography variant='h4' sx={{ mt: 6, textAlign: 'center' }}>Поделитесь впечатлениями</Typography>
                  <SendComment user={user} />
              </Item>
          </Grid>
      </Grid>
    </Box>
  )
}
