// export const BASE_URL = 'http://localhost:5000'
// export const WEBSOCKET_BASE_URL = 'ws://localhost:5000/'

export const BASE_URL = 'https://server.oktava.me'
export const WEBSOCKET_BASE_URL = 'wss://server.oktava.me'

export const SUNO_API_URL = 'https://suno-api-new-lemon.vercel.app'

export const GET_AUDIO_INTERVAL = 40000
export const LIMIT_3 = 3
export const LIMIT_5 = 5
export const LIMIT_8 = 8
export const LIMIT_10 = 10
export const LIMIT_25 = 25
export const LIMIT_50 = 50

export const DEFAULT_CREDITS = 30

// statuses
export const GENERAL = 'general' // все, кроме архивных
export const ARCHIVE = 'archive' // архивные
export const FAVORITE = 'favorite' // добавленные в избранные

export const LIKE_RECEIVER = 'receiver'
export const LIKE_SENDER = 'sender'

export const EVENT_TYPES = {
    addComment: 'опубликовал(а) комментарий к Вашей песне'
}

// apps
export const ADMIN = 'admin'
export const CABINET = 'cabinet'
