import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation  } from 'react-router-dom'
import { selectIsAuth } from '../../redux/slices/auth'
import { Typography, Avatar, Stack, Link, MenuItem, Paper, ListItemIcon, ListItemText } from '@mui/material'
import { BASE_URL } from '../../config'
import { stringToColor } from '../../clientHelpers/stringToColor'
import moment from 'moment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HouseIcon from '@mui/icons-material/House';
import PlaceIcon from '@mui/icons-material/Place';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import AssistantIcon from '@mui/icons-material/Assistant';
import ChatIcon from '@mui/icons-material/Chat';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';

export default function UserInfo({ user }) {
  const isAuth = useSelector(selectIsAuth)
  const location = useLocation()
  const isCabinet = location.pathname.includes('/cabinet')

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: isCabinet ? 100 : 200,
        height: isCabinet ? 100 : 200,
        fontSize: isCabinet ? '28px' : '56px'
      },
      children: `${name[0]}`,
    };
  }

  return (
    <>
      <Stack direction={isCabinet ? 'row' : 'column'} sx={{ alignItems: 'center' }}>
        <Link href={`/user/${user.login}`} sx={{ textDecoration: 'none' }}>
          <Avatar
              {...stringAvatar(`${user.name || user.login}`)}
              src={ user.avatar ? `${BASE_URL}${user.avatar}` : ''}
          />
        </Link>
        <Link
          href={`/user/${user.login}`}
          variant={isCabinet ? 'h5' : 'h4'}
          sx={ isCabinet ? { ml: 2, textDecoration: 'none' } : { mt: 3, textAlign: 'center', textDecoration: 'none' }}
        >
            {user.name}
        </Link>
      </Stack>

      {
        isAuth && isCabinet && (
          <Paper sx={{ border: '1px solid #1976D9', mt: 3, pt: 0.5, pb: 0.5 }}>
              <MenuItem sx={{ mb: 0.5 }} component={Link} href='/cabinet'>
                <ListItemIcon>
                  <LibraryMusicIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: '#1976D9'}}>
                  Песни
                </ListItemText>
              </MenuItem>

              <MenuItem sx={{ mb: 0.5 }} component={Link} href='/cabinet/studio'>
                <ListItemIcon>
                  <AudiotrackIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: '#1976D9'}}>
                  Студия
                </ListItemText>
              </MenuItem>

              {/* <MenuItem sx={{ mb: 0.5 }} component={Link} href='/cabinet/songs'>
                <ListItemIcon>
                  <LibraryMusicIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: '#1976D9'}}>
                  Песни
                </ListItemText>
                <Typography variant="body2" color="text.secondary">
                  0
                </Typography>
              </MenuItem>*/}

              <MenuItem sx={{ mb: 0.5 }} component={Link} href='/cabinet/comments'>
                <ListItemIcon>
                  <ChatIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: '#1976D9'}}>
                  Комментарии
                </ListItemText>
              </MenuItem>

              <MenuItem sx={{ mb: 0.5 }} component={Link} href='/cabinet/events'>
                <ListItemIcon>
                  <AssistantIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: '#1976D9'}}>
                  События
                </ListItemText>
              </MenuItem>

              <MenuItem sx={{ mb: 0.5 }} component={Link} href='/cabinet/profile'>
                <ListItemIcon>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: '#1976D9'}}>
                  Профиль
                </ListItemText>
              </MenuItem>

              <MenuItem component={Link} href={`/user/${user.login}`}>
                <ListItemIcon>
                  <PlaceIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: '#1976D9'}}>
                  Моя страница
                </ListItemText>
              </MenuItem>
          </Paper>
        )
      }

      <Stack direction='column' sx={{ width: '100%'}}>
          <Typography variant='body2' sx={{ mt: 3 }}>Опубликовано песен: { user.countSongs ?? 'нет данных' }</Typography>
          <Typography variant='body2' sx={{ mt: 1 }}>Последний визит: {moment(user.lastVisit).format('DD.MM.YYYY HH:mm')}</Typography>
          <Typography variant='body2' sx={{ mt: 1 }}>Регистрация: {moment(user.createdAt).format('DD.MM.YYYY HH:mm')}</Typography>
      </Stack>
    </>
  )
}
