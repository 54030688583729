import React from 'react'
import { ScrollView } from 'react-native'
import { Typography, Box, Stack, Link } from '@mui/material'
import { addLineBreaks } from '../../../clientHelpers/addLineBreaks'
import { useSelector } from 'react-redux'
import ExtendSongCard from '../../../components/ExtendSongCard'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { setSelectedSong } from '../../../redux/slices/general'
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { settingHelp } from './helps'
import { BASE_URL } from '../../../config'
import s from './index.module.css'

export default function SelectedSong() {
    const dispatch = useDispatch()

    let extendFrom = []
    const { selectedSong } = useSelector(state => state.general)
    const songs = useSelector(state => state.songs.songs.items)

    if (!!selectedSong?.metadata?.audio_prompt_id) {
        extendFrom = songs.filter(item => item.song.id === selectedSong.metadata.audio_prompt_id)
    }

    return (
        <>
            {
                Object.keys(selectedSong).length ? (
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant="h5" sx={{mb: 2}}>Выбранная композиция</Typography>
                        <Link component='button' sx={{mb: 2}} onClick={() => dispatch(setSelectedSong({}))}>
                            <CloseIcon />
                        </Link>
                    </Stack>
                ) : (
                    <Typography variant="h5" sx={{mb: 2}}>Помощь</Typography>
                )
            }

            <ScrollView style={{
                height: '600px',
                maxHeight: '69vh',
                paddingRight: '16px'
            }}>
                {
                    Object.keys(selectedSong).length ? (
                        <>
                            {
                                extendFrom.length > 0 && extendFrom[0] && (
                                    <>
                                        <ExtendSongCard song={extendFrom[0].song} isExtendFrom={true} />
                                        <Box sx={{ textAlign: 'center', mb: 1 }}>
                                            <KeyboardDoubleArrowDownIcon />
                                        </Box>
                                    </>
                                )
                            }

                            <img className={s.image} src={`${BASE_URL}/uploads/oktava.png`} />

                            <Typography variant='h6' sx={{ mt: 2 }}>{ selectedSong.title }</Typography>
                            <Box sx={{ display: 'inline-flex', mt: 1 }}>
                                <Typography variant='caption' color='gray'>{selectedSong?.tags}</Typography>
                            </Box>
                            <Typography variant='body2' sx={{ mt: 2 }}>{ addLineBreaks(selectedSong.prompt || '') }</Typography>
                        </>
                    ) : (
                        <Stack direction='column'>
                                <Typography variant='h6'>Раздел Настройки</Typography>
                                { settingHelp() }
                        </Stack>
                    )
                }
            </ScrollView>
        </>
    )
}
