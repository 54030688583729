import React, { useEffect } from 'react'
import { useWindowDimensions  } from 'react-native';
import { Typography, Grid, Paper, Container, Button, List, ListItem, ListItemText, Box, Link, Stack } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSongsList, fetchLikesByUserIdAndItemIds, fetchTopTags } from '../../redux/slices/songs'
import { selectIsAuth } from '../../redux/slices/auth'
import SongListPublic from '../../components/SongListPublic'
import SongCardPublic from '../../components/SongCardPublic'
import Player from '../../components/Player'
import { LIMIT_10, LIMIT_8 } from '../../config'
import TagCard from '../../components/TagCard';

export default function Home() {
  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsAuth)
  const user = useSelector(state => state.auth.data)
  const { width } = useWindowDimensions();
  const isTablet = width > 768

  useEffect(()=> {
    dispatch(fetchTopTags({ limit: LIMIT_8 }))
  }, [dispatch])
  const topTags = useSelector(state => state.songs.topTags)

  useEffect(()=> {
    dispatch(fetchSongsList({ page: 1, limit: LIMIT_10 }))
  }, [dispatch])
  const songsList = useSelector(state => state.songs.songsList)
  const songListIds = songsList.items.map(item => item._id)

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchLikesByUserIdAndItemIds({ sender: user._id, itemsArray: songListIds }))
    }
  }, [dispatch, JSON.stringify(songsList)])
  const likesArray = useSelector(state => state.songs.likes)

  const createSongButton = isAuth ? '/cabinet/studio' : '/signup'

  return (
    <>
        <Player />
        <Paper sx={{ padding: 2 }}>
        <Typography variant='h3' sx={{ mb: 2 }}>Место, где стихи оживают в звуках песен</Typography>
        <Typography variant='subtle2'>Наши уникальные алгоритмы позволяют вам превращать любые стихотворные
          тексты в оригинальные музыкальные композиции всего за несколько простых шагов.
          Наш сервис автоматически создаст песню, подчеркивая эмоциональную и художественную силу ваших слов.
          Послушайте, какие песни уже создали наши пользователи:
        </Typography>

          <Typography variant='h3' sx={{ mt: 4 }}>Новые песни</Typography>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            {
              songsList.items.map((item, index) => (
                <Grid item xs={12} sm={12} md={12} key={index}>
                    {
                      isTablet ? (
                        <SongListPublic
                          item={ item }
                          likesArray={ likesArray }
                          initialSongList='songsList'
                        />
                      ) : (
                        <SongCardPublic
                          item={ item }
                          likesArray={ likesArray }
                          initialSongList='songsList'
                        />
                      )
                    }
                </Grid>
            ))}
          </Grid>
          <Box sx={{ textAlign: 'center' }}>
            <Button variant='contained' href='/songs' sx={{ borderRadius: '16px', pl: 4, pr: 4, mt: 3 }}>Все песни</Button>
          </Box>

          <Typography variant='h3' sx={{ mt: 4, mb: 2 }}>Популярные жанры и стили</Typography>
          <Grid container spacing={3} sx={{ mt: 2 }}>
          {
            topTags.items.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <TagCard tag={item[0]}  />
              </Grid>
            ))
          }
          </Grid>

        <Typography variant='h3' sx={{ mt: 4, mb: 2 }}>Как создать песню</Typography>
        <Typography variant='subtle2' sx={{ mt: 2 }}>
          Создание песен еще никогда не было таким простым и доступным! Всего три простых шага:
          </Typography>

          <List component="nav">
            <ListItem>
                <ListItemText primary="Добавьте ваш текст: Просто скопируйте и вставьте ваш текст стихотворения или песни в наш интерфейс." />
            </ListItem>
            <ListItem>
                <ListItemText primary="Выберите стиль и настроение: Выберите жанр и настроение, которые хотите передать через вашу музыку. Наш алгоритм адаптируется под ваши предпочтения, создавая соответствующую атмосферу." />
            </ListItem>
            <ListItem>
                <ListItemText primary="Получите вашу песню: Наш сервис генерирует и предоставляет вам готовую песню в формате, который легко скачать или поделиться в социальных сетях, за считанные секунды." />
            </ListItem>
          </List>

        <Typography variant='h3' sx={{ mt: 4 }}>Преимущества Октавы</Typography>
        <List component="nav">
            <ListItem>
                <ListItemText primary="Скорость и удобство: Наш сервис предлагает быстрое и автоматизированное создание музыки на основе ваших слов без необходимости в специальных знаниях." />
            </ListItem>
            <ListItem>
            <ListItemText primary="Индивидуальный подход: Мы учитываем ваши предпочтения и стиль, создавая уникальную музыку, которая подчеркивает уникальность вашего текста." />
            </ListItem>
            <ListItem>
                <ListItemText primary="Качество звучания: Все композиции генерируются с использованием высококачественных звуковых библиотек, гарантируя превосходное звучание каждого созданного трека." />
            </ListItem>
          </List>

        <Typography variant='h6' sx={{ mt: 2 }}>
          Используйте Октаву, чтобы добавить новые измерения вашему творчеству и поделиться вашими произведениями с миром. Начните сегодня и превратите ваши стихи в уникальные музыкальные шедевры!
        </Typography>

        <Box sx={{ textAlign: 'center', mt: 4, mb: 4 }}>
          <Link
              underline='none'
              href={createSongButton}
              sx={{
                background: 'gold',
                padding: '8px 48px 16px 48px',
                color: 'black',
                borderRadius: '32px',
                fontSize: '24px'
              }}
          >
            Создать песню
          </Link>
        </Box>


        </Paper>
    </>

  )
}
