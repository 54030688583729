import React from 'react'
import { useWindowDimensions, Text } from 'react-native';
import { Stack, Typography, Link, Grid } from '@mui/material';
import moment from 'moment';
import { formatSeconds } from '../../clientHelpers/formatSeconds'
import PlayButton from '../PlayButton';
import s from './index.module.css'
import { BASE_URL } from '../../config'
import SongStats from '../SongStats'

export default function SongList({ item, likesArray, initialSongList, columns=1 }) {
  return (
    <Stack className={s.card} direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'}>
          <Stack justifyContent={'center'}>
            <img className={s.image} src={`${BASE_URL}/uploads/oktava.png`} />
          </Stack>
          {
            columns === 1 ? (
              <Stack sx={{ ml: 2 }} justifyContent={'center'}>
                <Grid container alignItems="center" spacing={2} wrap="nowrap">
                  <Grid item>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <Link href={`/song/${item._id}`} underline="none">
                        {item.song.title}
                      </Link>
                      </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" sx={{ color: 'gray' }}>
                      {item.song.tags}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container alignItems="center" spacing={2} wrap="nowrap">
                  <Grid item>
                    <Typography variant='body2' sx={{ mt: 0.5 }}>
                      <Link href={`/user/${item.user.login}`} underline="none">{ item.user.name }</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='caption' sx={{ color: 'gray', mt: 0.5 }}>{ moment(item.song.created_at).format('DD.MM.YYYY HH:mm') }</Typography>
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <Stack sx={{ ml: 2, width: 'auto' }} justifyContent={'center'}>
                <Typography variant='body1' sx={{ fontWeight: 600 }}>
                  <Link href={`/song/${item._id}`}  underline="none">{ item.song.title }</Link>
                </Typography>
                <Typography variant='body2' sx={{ mt: 0.5 }}>
                  <Link href={`/user/${item.user.login}`} underline="none">{ item.user.name }</Link>
                </Typography>
                <Typography variant='caption' color='gray' sx={{ mt: 0.5 }}>
                  {item.song.tags}
                </Typography>
                <Typography variant='caption' color='gray' sx={{ mt: 0.5 }}>{ moment(item.song.created_at).format('DD.MM.YYYY HH:mm') }</Typography>
              </Stack>
            )
          }
        </Stack>

        <Stack direction={'row'}>
          <SongStats
            item={item}
            likesArray={likesArray}
            initialSongList={initialSongList}
          />
          <Stack sx={{ ml: 3 }} justifyContent={'center'}>
                <PlayButton song={item.song} />
                <Typography variant='body2' sx={{ ml: 1 }}>{ item.song.duration ? formatSeconds(item.song?.duration) : '-- : --' }</Typography>
              </Stack>
          </Stack>
        </Stack>
  )
}
